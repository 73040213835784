import { gatewayURL, shortAddress } from '../utils/constants';

export const addPolygonLayer = async (map, polygons) => {
  if (!map.getLayer(`nvg8-polygon-layer`)) {
    map.addSource('nvg8-polygon', {
      type: 'geojson',
      data: polygons,
    });

    // Add a layer showing the state polygons.
    map.addLayer({
      id: 'nvg8-polygon-layer',
      type: 'fill',
      source: 'nvg8-polygon',
      paint: {
        'fill-color': 'transparent',
        'fill-outline-color': 'transparent',
      },
    });

    // Change the cursor to a pointer when
    // the mouse is over the nvg8-polygon layer.
    map.on('mouseenter', 'nvg8-polygon-layer', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change the cursor back to a pointer
    // when it leaves the nvg8-polygon layer.
    map.on('mouseleave', 'nvg8-polygon-layer', () => {
      map.getCanvas().style.cursor = '';
    });
  }
};
export const addTilesLayerToMap = async (map, tilesData, polygons) => {
  addPolygonLayer(map.current, polygons);

  if (!map.current.getLayer(`clusters`)) {
    await map.current.loadImage('/images/marker.png', async (error, image) => {
      if (error) {
        console.log(error);
      }

      // Add the image to the map style.
      if (!map.current.hasImage('pointer'))
        await map.current.addImage('pointer', image);

      await map.current.addSource('aerial', {
        type: 'geojson',
        data: tilesData,
        // data: ClusterJson,
        cluster: true,
        clusterMaxZoom: 20, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      });

      await map.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'aerial',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#15C3AD',
          'circle-stroke-color': 'transparent',
          'circle-stroke-width': 20,
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            10,
            99,
            12,
            999,
            15,
            9999,
            20,
          ],
        },
      });

      await map.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'aerial',
        filter: ['has', 'point_count'],
        layout: {
          'icon-image': 'pointer',
          'icon-size': 0.18,
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
          'icon-anchor': 'top-left',
        },
      });

      await map.current.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'aerial',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': 'transparent',
          'circle-radius': 10,
          'circle-stroke-width': 0,
        },
      });

      await map.current.addLayer({
        id: 'cluster-count-1',
        type: 'symbol',
        source: 'aerial',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'pointer',
          'icon-size': 0.15,
        },
      });

      // inspect a cluster on click
      map.current.on('click', 'clusters', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        map.current
          .getSource('aerial')
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            console.log(features[0].geometry.coordinates);
            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      map.current.on('mouseenter', 'clusters', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'clusters', () => {
        map.current.getCanvas().style.cursor = '';
      });
      map.current.on('mouseenter', 'unclustered-point', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'unclustered-point', () => {
        map.current.getCanvas().style.cursor = '';
      });
    });
  }
};

export const addRasterLayer = (map, layer) => {
  const id = layer.properties.featureId;
  const bounds = layer.properties.bounds
    ? typeof layer.properties.bounds === 'string'
      ? JSON.parse(layer.properties.bounds)
      : layer.properties.bounds
    : [-180, -85.051129, 180, 85.051129]; //default

  if (map.current.getLayer('unclustered-point')) {
    if (layer && !map.current.getLayer(`tms-nvg8-layer-${id}`)) {
      let tmsURL = '';
      if (
        layer.properties.tms.includes('https://navmaps-dev.s3.amazonaws.com')
      ) {
        let tms = layer.properties.tms.split('/');
        tms = tms.length && tms[tms.length - 1];
        tmsURL = `${gatewayURL}/${tms}/{z}/{x}/{y}.png`;
      } else {
        tmsURL = `${layer.properties.tms}/{z}/{x}/{y}.png`;
      }
      map.current.addSource(`tms-nvg8-source-${id}`, {
        type: 'raster',
        scheme: 'tms',
        tiles: [tmsURL],
        tileSize: 256,
        bounds: [...bounds],
      });
      map.current.addLayer({
        id: `tms-nvg8-layer-${id}`,
        type: 'raster',
        source: `tms-nvg8-source-${id}`,
      });
      map.current.moveLayer(`tms-nvg8-layer-${id}`, 'unclustered-point');
      map.current.moveLayer(`tms-nvg8-layer-${id}`, 'clusters');
      map.current.moveLayer(`tms-nvg8-layer-${id}`, 'state-label');
      map.current.moveLayer(`tms-nvg8-layer-${id}`, 'road-label');
    }
  } else {
    if (layer && !map.current.getLayer(`tms-nvg8-layer-${id}`)) {
      let tmsURL = '';
      if (
        layer.properties.tms.includes('https://navmaps-dev.s3.amazonaws.com')
      ) {
        let tms = layer.properties.tms.split('/');
        tms = tms[tms.length - 1];
        tmsURL = `${gatewayURL}/${tms}/{z}/{x}/{y}.png`;
      } else {
        tmsURL = `${layer.properties.tms}/{z}/{x}/{y}.png`;
      }
      map.current.addSource(`tms-nvg8-source-${id}`, {
        type: 'raster',
        scheme: 'tms',
        tiles: [tmsURL],
        tileSize: 256,
        bounds: [...bounds],
      });
      map.current.addLayer({
        id: `tms-nvg8-layer-${id}`,
        type: 'raster',
        source: `tms-nvg8-source-${id}`,
      });
    }
  }
};

export const addMapillarySource = (mapRef, setImageId, setMapillaryFeature) => {
  const map = mapRef.current;
  map.addSource('mapillary', {
    type: 'vector',
    url: process.env.REACT_APP_TILESET_URL,
    minzoom: 8,
    maxzoom: 14,
  });
  map.addLayer({
    id: 'mapillary-sequences',
    type: 'line',
    source: 'mapillary',
    'source-layer': 'sequence',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#05CB63',
      'line-width': 1,
    },
  });
  map.addLayer({
    id: 'mapillary-images',
    type: 'circle',
    source: 'mapillary',
    'source-layer': 'image',
    paint: {
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        '#0c55f2', // if selected
        '#05CB63',
      ],
      'circle-radius': 5,
    },
  });
  var featureId = null;

  map.on('mouseenter', 'mapillary-images', () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', 'mapillary-images', () => {
    map.getCanvas().style.cursor = '';
  });
  map.on('click', 'mapillary-images', (e) => {
    // map.getCanvas().style.cursor = 'pointer';
    if (e.features.length > 0) {
      if (featureId) {
        map.removeFeatureState({
          source: 'mapillary',
          sourceLayer: 'image',
          id: featureId,
        });
      }
      featureId = e.features[0].id;
      map.setFeatureState(
        {
          source: 'mapillary',
          sourceLayer: 'image',
          id: featureId,
        },
        {
          clicked: true,
        }
      );
    }
    setMapillaryFeature(e.features[0].properties);
    setImageId(e.features[0].properties.id);
  });
};

export const addDataLayerToMapillary = async (mapRef, data) => {
  const map = mapRef.current;

  if (!map.getLayer(`mapillary-data-layer`)) {
    map.addSource('mapillary-data', {
      type: 'geojson',
      // 'data': 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson'
      // 'data': process.env.REACT_APP_CLUSTER_URL
      data: data,
    });

    map.addLayer({
      id: 'mapillary-data-layer',
      type: 'heatmap',
      source: 'mapillary-data',
      maxzoom: 9,
      paint: {
        'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 3],
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0,
          'rgba(33,102,172,0)',
          1,
          '#1988CB',
        ],
        'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20],
        'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0],
      },
    });
  }
};
