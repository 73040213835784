import { lazy, Suspense, useState } from "react"
import ToggleButton from "@material-ui/lab/ToggleButton";

const Editor = lazy(() => import('../Editor/Editor'));

export const DSLEditor = ({ openEditor, handleCloseEditor, handleOpenEditor, map, classes }) => {
    const [dslCode, setDslCode] = useState(`zoom(13);\
    \nmoveLocation([-97.770017,30.368766]);\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n`);
    const [logs, setLogs] = useState([])

    return (
        <div
            style={{
                position: "absolute",
                right: openEditor ? 80 : 35,
                top: openEditor ? 60 : 182,
                display: "flex",
                textAlign: "left",
                zIndex: 5,

            }}
        >
            <Suspense fallback={<></>}>
                {!openEditor ? <span className="toggleButtonClass">
                    <ToggleButton
                        onClick={handleOpenEditor}
                        className={classes.toggleButton}
                    >
                        <img src="/images/cartographer.svg" alt="" width={17.6} height={17.6} />
                    </ToggleButton>
                </span> :
                    <Editor map={map} handleClose={handleCloseEditor}
                        open={openEditor} setDslCode={setDslCode} dslCode={dslCode}
                        logs={logs} setLogs={setLogs}

                    />
                }
            </Suspense>
        </div>
    )
}

export default DSLEditor