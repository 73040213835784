import axios from "axios";
import { baseURL } from '../utils/constants';

export async function getRequest(url) {
  try {
    const data = await axios.get(url);
    return data;
  } catch (error) {
    console.log("Error", error);
  }
}
export async function getGeoJSON(url, headers) {
  try {
    const response = await axios.get(url);
    let data = response?.data;
    return { data, success: true };
  } catch (error) {
    console.log("Error", error);
    return { response: error.response, success: false };
  }
}
export async function getMapillaryFeatures(url, headers) {
  try {
    const response = await axios.get(url);
    let data = response?.data;
    return { data, success: true };
  } catch (error) {
    console.log("Error", error);
    return { response: error.response, success: false };
  }
}

export async function postMapEvent(event) {
  try {
    const { mosaics, type, location, center, layer } = event
    const body = { event_type: type, mosaic_ids: mosaics, location, center, layer, description: "" };
    const response = await axios.post(`${baseURL}map-events`, body);
    let data = response?.data;
    console.log({ data })
    return { data, success: true };
  } catch (error) {
    console.log("Error", error);
    return { response: error.response, success: false };
  }
}
