import ExpandLessIcon from '@material-ui/icons/ExpandLess';

export const ToggleMappillary = ({ setToggleMapillary }) => {
    return (
        <div
            onClick={() => setToggleMapillary(val => !val)}

            style={{
                background: "rgba(0,0,0,0.5)",
                padding: "0px",
                height: "70px", width: "70px",
                position: 'absolute',
                top: 0, right: 0,
                cursor: 'pointer',
                display: "flex", justifyContent: "end", alignItems: "start",
                zIndex: 5,
                clipPath: "polygon(0 0, 100% 0, 100% 100%)"
            }}>
            <ExpandLessIcon style={{ color: "#ffff", fontSize: "40px", rotate: "45deg", }} />
        </div>
    )
}

export default ToggleMappillary