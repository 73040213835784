import React, { useState, Suspense, } from "react";
import Home from "./Home";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../utils/theme";
import "../../mapillary.css";
import "mapbox-gl/dist/mapbox-gl.css";
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import ConsentBanner from '../../submodule/web3-login/components/GA/ConsentBanner';


const HomePage = () => {
  const [showStreet, setShowStreet] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<></>}>
        <Home setShowStreet={setShowStreet} showStreet={showStreet} />
        <ConsentBanner />
      </Suspense>
    </ThemeProvider>
  );
};
export default HomePage;
