import React, { useRef, useEffect, useState, lazy, Suspense } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { Grid } from '@material-ui/core';
import {
  BackDropChildren,
  BackDropCustom,
  Tooltip,
  LocationDetails,
  SearchBar,
  RightMenu,
  DSLEditor,
  FeedbackButton,
  LeftMenu,
} from '../../components';
import {
  addTilesLayerToMap,
  addRasterLayer,
  addMapillarySource,
  addDataLayerToMapillary,
} from '../../components/AddTilesLayerToMap';
import { useStyles } from './Style';
import { getGeoJSON, postMapEvent, getMapillaryFeatures } from '../../Api/api';
import Joyride from 'react-joyride';
import {
  backendURL,
  baseURL,
  gatewayURL,
  TutorialSteps,
} from '../../utils/constants';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTile } from '../../redux/reducers/tile';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import MapillaryViewer from '../../components/Mapillary/Mapillary';
import Footer from '../../components/Layout/Footer';
import {
  syncZoom,
  syncRotate,
  removeDashCamLayers,
  removeAerialLayers,
  removeRasterLayers,
  removeDynamicGeoJsonLayers,
  addRasterLayerToUnClustered,
  debounce,
  shortAddress,
} from '../../utils/helper';
import { getQueryParam } from '../../utils/helper';

const ToggleMappillary = lazy(() =>
  import('../../components/Home/ToggleMappillary')
);
const Slider = lazy(() => import('../../components/Home/CompareSlider'));
const Report = lazy(() => import('../../components/Feedback/Report'));
const Feedback = lazy(() => import('../../components/Feedback'));

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;

function Home({ setShowStreet, showStreet }) {
  const dispatch = useDispatch();
  const mapContainer = useRef(null);
  const windowSize = useWindowSize();
  const selectedTile = useSelector((state) => state.tile.selectedTile);
  const selectedTileRef = React.useRef(selectedTile);
  const featureCollection = React.useRef(null);
  const mapillaryCollection = React.useRef(null);
  const showStreetRef = useRef(showStreet);
  const checkWindowSize =
    windowSize !== undefined &&
    windowSize.width !== undefined &&
    windowSize.width > 710;
  const classes = useStyles();
  const map = useRef(null);
  const [active, setIsActive] = useState(true);
  const [toggleVal, setToggle] = useState('satellite-streets-v11');
  const [showSlider, setShowSlider] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [opacity, setOpacity] = useState(100);
  const [satelliteView, setSatelliteView] = useState(false);
  const [openFeedback, setOpenFeedback] = React.useState(false);
  const [openReport, setOpenReport] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [tutorialSkippedOrCompleted, setTutorialSkippedOrCompleted] =
    useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const [currentImageId, setCurrentImageId] = useState(null);
  const [mapillaryFeature, setMapillaryFeature] = useState(null);
  const [toggleMapillary, setToggleMapillary] = useState(false);
  const [locateClicked, setLocateClicked] = useState(false);
  const [openLeftMenu, setOpenLeftMenu] = React.useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const zoomControls = new mapboxgl.NavigationControl();
  const userLocationToggle = new mapboxgl.GeolocateControl({
    trackUserLocation: true,
  });
  const searchBar = SearchBar(mapboxgl);
  const polygons = useRef({
    type: 'FeatureCollection',
    features: [],
  });
  const paramMosaicID = getQueryParam('mosaic_id');

  console.log({ paramMosaicID });

  const handleOpenEditor = () => setOpenEditor(true);
  const handleCloseEditor = () => setOpenEditor(false);

  const handleRotateEnd = (e) => {
    const triggeredBy = e.originalEvent ? 'manual' : 'cartographer';
    const layer = showStreetRef.current ? 'dashcam' : 'aerial';

    setTimeout(
      () =>
        addRasterLayerToUnClustered(
          map,
          polygons,
          addRasterLayer,
          'rotateend',
          triggeredBy,
          layer
        ),
      500
    );
  };
  const rotateEndHandler = debounce(handleRotateEnd, 200);

  const mapSettings = () => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      zoom: 0,
      center: [-74.5447, 40.6892], // New York
      style: `mapbox://styles/mapbox/${toggleVal}`,
      transformRequest: (url, resourceType) => {
        if (
          resourceType === 'Tile' &&
          gatewayURL &&
          url.startsWith(gatewayURL)
        ) {
          return {
            url: url,
            headers: { 'x-api-key': process.env.REACT_APP_GATEWAY_API_KEY },
          };
        }
      },
    });

    map.current.addControl(searchBar, 'top-left');
    map.current.addControl(userLocationToggle, 'top-left');
    map.current.addControl(zoomControls, 'top-right');

    map.current.on('load', async () => {
      if (process.env.REACT_APP_CLUSTER_URL) {
        setIsActive(true);
        await addTilesLayerToMap(
          map,
          process.env.REACT_APP_CLUSTER_URL,
          polygons.current
        );
        setIsActive(false);
      } else {
        setIsActive(true);
        const result = await getGeoJSON(`${baseURL}odm/cluster-json`);
        if (result?.success) {
          const data = result?.data;
          featureCollection.current = data;
          await addTilesLayerToMap(map, data, polygons.current);
        }
        if (paramMosaicID) {
          const mosaic_data = await getGeoJSON(
            `${baseURL}mosaic/${paramMosaicID}`
          );
          console.log({ mosaic_data });
          const coordinates = mosaic_data?.data?.coordinates;
          console.log({ coordinates });
          if (coordinates && Array.isArray(coordinates)) {
            await map.current.flyTo({
              center: [...coordinates],
              zoom: 20,
            });
          }
        }
        setIsActive(false);
      }

      map.current.on('click', `nvg8-polygon-layer`, (e) => {
        handleOpenDetails({
          properties: e?.features[0]?.properties,
          geometry: e?.features[0]?.geometry,
        });
      });

      map.current.on('click', 'unclustered-point', (e) => {
        const zoom = map?.current?.getZoom();
        if (zoom < 15) {
          map.current.flyTo({
            center: e?.features[0]?.geometry.coordinates,
            zoom: 15,
          });
        } else {
          map.current.flyTo({
            center: e?.features[0]?.geometry.coordinates,
          });
        }
        if (
          !map.current.getLayer(
            `tms-nvg8-layer-${e?.features[0]?.properties.featureId}`
          )
        ) {
          addRasterLayerToUnClustered(map, polygons, addRasterLayer);
        }
        handleOpenDetails({
          properties: e?.features[0]?.properties,
          geometry: e?.features[0]?.geometry,
        });
      });
      map.current.on('zoomend', function (e) {
        const triggeredBy = e.originalEvent ? 'manual' : 'cartographer';
        const layer = showStreetRef.current ? 'dashcam' : 'aerial';
        setTimeout(
          () =>
            addRasterLayerToUnClustered(
              map,
              polygons,
              addRasterLayer,
              'zoomend',
              triggeredBy,
              layer
            ),
          500
        );
      });

      map.current.on('dragend', (e) => {
        const triggeredBy = e.originalEvent ? 'manual' : 'cartographer';
        const layer = showStreetRef.current ? 'dashcam' : 'aerial';
        setTimeout(
          () =>
            addRasterLayerToUnClustered(
              map,
              polygons,
              addRasterLayer,
              'dragend',
              triggeredBy,
              layer
            ),
          500
        );
      });

      map.current.on('rotateend', rotateEndHandler);

      map.current.on('styledata', async () => {
        if (!showStreetRef.current) {
          if (!map.current.getLayer(`clusters`)) {
            setOpacity(100);
            if (process.env.REACT_APP_CLUSTER_URL) {
              if (!map.current.getLayer(`clusters`)) {
                await addTilesLayerToMap(
                  map,
                  process.env.REACT_APP_CLUSTER_URL,
                  polygons.current
                );
                setTimeout(
                  () =>
                    addRasterLayerToUnClustered(map, polygons, addRasterLayer),
                  2000
                );
              }
            } else {
              if (
                featureCollection.current &&
                !map.current.getLayer(`clusters`)
              ) {
                await addTilesLayerToMap(
                  map,
                  featureCollection.current,
                  polygons.current
                );
                setTimeout(
                  () =>
                    addRasterLayerToUnClustered(map, polygons, addRasterLayer),
                  2000
                );
              }
            }
            if (map.current.getLayer(`mapillary-images`)) {
              removeDashCamLayers(map);
            }
          }
        } else {
          if (map.current.getLayer(`clusters`)) {
            handleCloseDetails();
            if (showSlider) {
              toggleSlider();
            }
            removeRasterLayers(map);
            removeAerialLayers(map, polygons);
          }
          if (!map.current.getLayer(`mapillary-images`)) {
            addMapillarySource(map, setCurrentImageId, setMapillaryFeature);
            if (!mapillaryCollection.current) {
              setIsActive(true);
              const mapillaryResult = await getMapillaryFeatures(
                `${backendURL}api/streetImagery/featureCollection`
              );
              if (mapillaryResult?.success) {
                const data = mapillaryResult?.data;
                mapillaryCollection.current = data;
              }
              setIsActive(false);
            }
            addDataLayerToMapillary(map, mapillaryCollection.current);
          }
        }
      });

      searchBar.on('result', async (e) => {
        const selectedValue = await e.result.place_name;
        const layer = showStreetRef.current ? 'dashcam' : 'aerial';
        const event = { type: 'search', location: selectedValue, layer };
        await postMapEvent(event);
      });
    });
  };

  useEffect(() => {
    const tutorial_skipped = localStorage.getItem('tutorial_skipped');
    const tutorial_completed = localStorage.getItem('tutorial_completed');
    if (
      (tutorial_skipped && tutorial_skipped) ||
      (tutorial_completed && tutorial_completed)
    ) {
      setTutorialSkippedOrCompleted(true);
    }
    mapSettings();
  }, []);

  useEffect(() => {
    showStreetRef.current = showStreet;
  }, [showStreet]);

  const toggleChange = () => {
    const leftIcons = document.getElementsByClassName(
      'mapboxgl-ctrl-group mapboxgl-ctrl'
    )[0];
    leftIcons.style.marginTop = '100px';
    const currentMode = map.current.getStyle().metadata['mapbox:origin'];
    const toggleValue =
      currentMode === 'satellite-streets-v11'
        ? 'streets-v11'
        : 'satellite-streets-v11';
    setToggle(toggleValue);
    setOpacity(100);
    if (toggleValue) {
      map.current.setStyle('mapbox://styles/mapbox/' + toggleValue);
    }
  };

  const satelliteViewHandler = async () => {
    if (map?.current) {
      const _toggleVal = await map.current.getStyle().metadata['mapbox:origin'];
      setToggle(_toggleVal);
      if (satelliteView) {
        if (_toggleVal !== 'satellite-streets-v11')
          map.current.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
      } else {
        if (_toggleVal !== toggleVal)
          map.current.setStyle(`mapbox://styles/mapbox/${toggleVal}`);
      }
    }
  };

  useEffect(() => {
    satelliteViewHandler();
  }, [satelliteView]);

  useEffect(() => {
    const searchBox = document.getElementsByClassName(
      'mapboxgl-ctrl-geocoder mapboxgl-ctrl'
    )[0];
    if (!checkWindowSize && !toggleMapillary) {
      searchBox.style.marginTop = '60px';
    } else {
      searchBox.style.marginTop = '20px';
    }
  }, [checkWindowSize]);
  useEffect(() => {
    const searchBox = document.getElementsByClassName(
      'mapboxgl-ctrl-geocoder mapboxgl-ctrl'
    )[0];
    if (toggleMapillary) {
      searchBox.style.display = 'none';
    } else {
      searchBox.style.display = 'block';
    }
  }, [toggleMapillary]);

  const toggleSlider = () => {
    setShowDetails((prevVal) => !prevVal);
    setShowSlider((prevVal) => !prevVal);
    setSatelliteView((prevVal) => !prevVal);
  };
  const handleOpenDetails = (tile) => {
    setShowSlider(false);
    setSatelliteView(false);
    if (selectedTileRef.current) {
      const id = selectedTileRef.current?.properties?.featureId;
      if (id !== tile.properties.featureId) {
        const layer = showStreetRef.current ? 'dashcam' : 'aerial';
        const event = {
          type: 'click',
          mosaics: [tile.properties.featureId],
          layer,
        };
        postMapEvent(event); //clicked another tile

        if (map.current.getLayer(`tms-nvg8-layer-${id}`)) {
          map.current.setPaintProperty(
            `tms-nvg8-layer-${selectedTileRef.current?.properties?.featureId}`,
            'raster-opacity',
            1
          );
        }
      }
    } else {
      const layer = showStreetRef.current ? 'dashcam' : 'aerial';
      const event = {
        type: 'click',
        mosaics: [tile.properties.featureId],
        layer,
      };
      postMapEvent(event); //clicked tile detail when no other detail is opened
    }
    dispatch(setSelectedTile(tile));
    selectedTileRef.current = tile;
    setShowDetails(true);
    setOpacity(100);
  };
  const handleCloseDetails = () => {
    if (selectedTileRef.current) {
      const id = selectedTileRef.current?.properties?.featureId;
      if (map.current.getLayer(`tms-nvg8-layer-${id}`)) {
        map.current.setPaintProperty(
          `tms-nvg8-layer-${selectedTileRef.current?.properties?.featureId}`,
          'raster-opacity',
          1
        );
      }
    }
    setShowDetails(false);
    dispatch(setSelectedTile(null));
    selectedTileRef.current = null;
    handleCloseMenu();
  };

  const handleChangeOpacity = (e) => {
    setOpacity(parseInt(e.target.value));

    if (selectedTile?.properties?.featureId) {
      map.current.setPaintProperty(
        `tms-nvg8-layer-${selectedTile.properties.featureId}`,
        'raster-opacity',
        parseInt(e.target.value, 10) / 100
      );
    }
  };

  const locateMe = () => {
    const locate = document.getElementsByClassName(
      'mapboxgl-ctrl-geolocate'
    )[0];
    if (locate) locate.click();
    setLocateClicked((val) => !val);
  };

  const handleClickOpenFeedback = () => {
    handleCloseMenu();
    setOpenFeedback(true);
  };

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  };
  const handleClickOpenReport = () => {
    handleCloseMenu();
    setOpenReport(true);
  };

  const handleCloseReport = () => {
    setOpenReport(false);
  };

  const handleClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleGoToNext = async (tile) => {
    handleCloseDetails();
    await map.current.flyTo({
      center: tile?.geometry.coordinates,
      zoom: 15,
    });

    handleOpenDetails({
      properties: tile?.properties,
      geometry: tile?.geometry,
    });
  };

  const reloadLayers = async () => {
    if (!showStreet) {
      if (map.current.getLayer(`mapillary-images`)) {
        removeDashCamLayers(map);
      }

      if (process.env.REACT_APP_CLUSTER_URL) {
        if (!map.current.getLayer(`clusters`)) {
          await addTilesLayerToMap(
            map,
            process.env.REACT_APP_CLUSTER_URL,
            polygons.current
          );
          setTimeout(
            () => addRasterLayerToUnClustered(map, polygons, addRasterLayer),
            2000
          );
        }
      } else {
        if (featureCollection.current && !map.current.getLayer(`clusters`)) {
          await addTilesLayerToMap(
            map,
            featureCollection.current,
            polygons.current
          );
          setTimeout(
            () => addRasterLayerToUnClustered(map, polygons, addRasterLayer),
            2000
          );
        }
      }
    } else {
      if (map.current.getLayer(`clusters`)) {
        handleCloseDetails();
        setShowSlider(false);
        removeRasterLayers(map);
        removeAerialLayers(map, polygons);
      }
      if (!currentImageId) {
        map.current.flyTo({
          center: [-97.770017, 30.368766],
          zoom: 10,
        });
      }
      if (!map.current.getLayer(`mapillary-images`)) {
        addMapillarySource(map, setCurrentImageId, setMapillaryFeature);
        if (!mapillaryCollection.current) {
          setIsActive(true);
          const mapillaryResult = await getMapillaryFeatures(
            `${backendURL}api/streetImagery/featureCollection`
          );
          if (mapillaryResult?.success) {
            const data = mapillaryResult?.data;
            mapillaryCollection.current = data;
          }
          setIsActive(false);
        }
        addDataLayerToMapillary(map, mapillaryCollection.current);
      }
    }
  };

  useEffect(() => {
    reloadLayers();
  }, [showStreet]);

  useEffect(() => {
    console.log('mapillaryFeature', mapillaryFeature);
  }, [mapillaryFeature]);

  useEffect(() => {
    map?.current?.resize();
  }, [toggleMapillary]);

  const handleStreetView = (key) => {
    if (key === 'dashcam') {
      setShowStreet(true);
    } else {
      setShowStreet(false);
    }
    const event = { type: 'toggle', layer: key };
    postMapEvent(event);
    if (toggleMapillary) {
      setToggleMapillary(false);
    }
  };

  const resetAllSettings = async () => {
    if (locateClicked) {
      const locate = document.getElementsByClassName(
        'mapboxgl-ctrl-geolocate'
      )[0];
      if (locate) locate.click();
      setLocateClicked(false);
    }
    // const id = selectedTileRef.current?.properties?.featureId;
    // if (map.current.getLayer(`tms-nvg8-layer-${id}`)) {
    //   map.current.removeLayer(`tms-nvg8-layer-${id}`);
    //   map.current.removeSource(`tms-nvg8-source-${id}`); // remove previous
    // }
    selectedTileRef.current = null;
    handleCloseMenu();
    setSelectedTile(null);
    //********** reset base map **********
    const _toggleVal = await map.current.getStyle().metadata['mapbox:origin'];
    if (_toggleVal !== 'satellite-streets-v11')
      map.current.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
    if (toggleVal) setToggle('satellite-streets-v11');
    setShowSlider(false);
    setShowDetails(false);
    setOpacity(100);
    setSatelliteView(false);
    setOpenFeedback(false);
    setOpenReport(false);
    setNotification({
      open: false,
      message: '',
      severity: '',
    });
    setCurrentImageId(null);
    setMapillaryFeature(null);
    setToggleMapillary(false);
    setShowStreet(false);
    const search = document.getElementsByClassName(
      'mapboxgl-ctrl-geocoder--input'
    )[0];
    search.value = '';
    setOpenLeftMenu(false);
    // removeRasterLayers(map);
    setOpenEditor(false);
    // ********** remove all markers **********
    const cc = map.current.getContainer();
    const markers = cc.getElementsByClassName('mapboxgl-marker');
    const length = [...markers].length;
    let i = length - 1;
    while (i > -1) {
      markers[i]?.remove();
      i--;
    }

    // **********  remove all popups **********
    const popups = document.getElementsByClassName('mapboxgl-popup');
    let j = [...popups].length - 1;
    while (j > -1) {
      popups[j]?.remove();
      j--;
    }

    // **********  remove dynamic geo json layers **********
    removeDynamicGeoJsonLayers(map);

    // **********  reset zoom **********
    await syncZoom(map.current, 0.6);

    // **********  reset rotation **********
    await syncRotate(map.current, 0);
  };

  const getDateTime = (datetimeString) => {
    const dateObj = new Date(datetimeString);
    const date = dateObj.toLocaleDateString(); // gives the date
    const time = dateObj.toLocaleTimeString();
    return `${date} ${time}`;
  };
  const getDateTimeCapture = (datetimeString) => {
    const parts = datetimeString.split('_');

    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    const hour = parts[3];
    const minute = parts[4];
    const second = parts[5];
    const millisecond = parts[6];

    const date = `${year}-${month}-${day}`; // e.g. "2023-04-05"
    const time = `${hour}:${minute}:${second}.${millisecond}`; // e.g. "01:01:18.000"

    return `${date} ${time}`;
  };

  return (
    <div className="App">
      <Suspense fallback={<></>}>
        <BackDropCustom className={classes.backdrop} open={active}>
          <BackDropChildren />
        </BackDropCustom>

        {!active && !tutorialSkippedOrCompleted && (
          <>
            <Joyride
              steps={TutorialSteps}
              continuous
              disableBeacon
              showSkipButton
              disableOverlayClose
              tooltipComponent={Tooltip}
            />
          </>
        )}
        <div>
          <RightMenu
            checkWindowSize={checkWindowSize}
            toggleChange={toggleChange}
            resetAllSettings={resetAllSettings}
            classes={classes}
          />

          <div
            className={
              checkWindowSize ? classes.sideBarDivLG : classes.sideBarDivSM
            }
          >
            <LeftMenu
              locateMe={locateMe}
              showDetails={showDetails}
              showStreet={showStreet}
              handleStreetView={handleStreetView}
              setOpen={setOpenLeftMenu}
              open={openLeftMenu}
            />
          </div>

          {showSlider && (
            <Slider
              toggleSlider={toggleSlider}
              handleChangeOpacity={handleChangeOpacity}
              opacity={opacity}
              classes={classes}
            />
          )}
          <DSLEditor
            map={map}
            handleCloseEditor={handleCloseEditor}
            openEditor={openEditor}
            handleOpenEditor={handleOpenEditor}
            classes={classes}
          />
          <div className={toggleMapillary ? 'main-map-viewer' : ''}>
            <div
              ref={mapContainer}
              id={toggleMapillary ? 'streetViewer' : 'mapViewer'}
              style={
                toggleMapillary
                  ? { position: 'relative' }
                  : { minHeight: '94.5vh', height: 'auto' }
              }
            >
              {toggleMapillary ? (
                <ToggleMappillary setToggleMapillary={setToggleMapillary} />
              ) : (
                <></>
              )}
              <FeedbackButton
                classes={classes}
                handleClickOpenFeedback={handleClickOpenFeedback}
                handleClickOpenReport={handleClickOpenReport}
                handleClick={handleClick}
                selectedTile={selectedTile}
                anchorEl={anchorEl}
                toggleMapillary={toggleMapillary}
                mapillaryFeature={mapillaryFeature}
                showStreet={showStreet}
              />
              {showDetails && selectedTile && (
                <Grid
                  container
                  direction="row"
                  className={classes.locationDetails}
                  style={{
                    padding: 5,
                    paddingLeft: 15,
                    paddingRight: 15,
                    overflowY: 'auto',
                  }}
                >
                  <LocationDetails
                    closeClicked={handleCloseDetails}
                    map={map}
                    toggleSlider={toggleSlider}
                    handleGoToNext={handleGoToNext}
                  />
                </Grid>
              )}
              {openFeedback && (
                <Feedback
                  open={openFeedback}
                  handleClose={handleCloseFeedback}
                  setNotification={setNotification}
                />
              )}
              {openReport && (
                <Report
                  open={openReport}
                  handleClose={handleCloseReport}
                  setNotification={setNotification}
                  selectedTile={selectedTile}
                  mapillaryFeature={mapillaryFeature}
                  showStreet={showStreet}
                />
              )}
              {notification?.open && (
                <CustomSnackbar
                  open={notification?.open}
                  message={notification?.message}
                  severity={notification?.severity}
                  handleClose={() =>
                    setNotification({ open: false, message: '', severity: '' })
                  }
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />
              )}
            </div>
          </div>
          <div
            style={
              toggleMapillary
                ? { position: 'absolute', bottom: 0, width: '100%' }
                : {}
            }
          >
            <Footer darkBg={false} />
          </div>
        </div>
        {showStreet && currentImageId && (
          <>
            <MapillaryViewer
              imageId={currentImageId}
              map={map.current}
              setToggleMapillary={setToggleMapillary}
              toggleMapillary={toggleMapillary}
            />
            {/* <a href="https://www.mapillary.com/" target="_blank"> */}
            <div
              className={
                toggleMapillary
                  ? 'mapillary-attribution-option-map'
                  : 'mapillary-attribution-option'
              }
            >
              <div
                style={{
                  marginLeft: '5px',
                  height: '20px',
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                {' '}
                {/* Licensed under CC-BY 4.0 as contributors of Mapillary{' '} */}
                <div style={{ float: 'left' }}>
                  {mapillaryFeature?.role.toLowerCase() === 'whitelist'
                    ? `Licensed by Navigate foundation. `
                    : mapillaryFeature?.wallet_address
                    ? `Image by ${shortAddress(
                        mapillaryFeature?.wallet_address
                      )}`
                    : mapillaryFeature?.email
                    ? `Image by ${mapillaryFeature?.email}`
                    : `Licensed by Navigate foundation. `}{' '}
                </div>
                <div style={{ float: 'right' }}>
                  Captured At {getDateTimeCapture(mapillaryFeature.captured_at)}
                </div>
                <br></br>
              </div>
              <div
                style={{ position: 'absolute', bottom: '4px', left: '12px' }}
              >
                {mapillaryFeature.createdAT &&
                  `Uploaded At ${getDateTime(mapillaryFeature.createdAT)}`}{' '}
              </div>
            </div>
            {/* </a> */}
          </>
        )}
      </Suspense>
    </div>
  );
}

export default Home;
