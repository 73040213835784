import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { CircularProgress } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { RenderMode } from 'mapillary-js';
const Mapillary = require('mapillary-js');
const provider = new Mapillary.GraphDataProvider({
  endpoint: `${process.env.REACT_APP_BACKEND_URL}api/streetImagery/`,
});
const MapillaryViewer = ({
  imageId,
  map,
  toggleMapillary,
  setToggleMapillary,
}) => {
  const containerRef = useRef(null);
  const [mapilary, setMapilary] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 20);
  }, [toggleMapillary]);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;
    let mapillaryViewer = new Mapillary.Viewer({
      renderMode: RenderMode.Letterbox,
      // 'accessToken': 'MLY|6690365790977664|4295a0a62411dd186aa0a0d3704a2d33',
      container: containerRef.current,
      imageId: imageId,
      imageTiling: false,
      // component: {
      //   cover: false,
      // },

      dataProvider: provider,
    });
    mapillaryViewer.on('dataloading', function (e) {
      setIsLoading(e.loading);
    });
    var featureId = null;
    mapillaryViewer.on('image', function (e) {
      const query = map.queryRenderedFeatures({
        sourceID: 'mapillary-images',
      });
      if (featureId) {
        map.removeFeatureState({
          source: 'mapillary',
          sourceLayer: 'image',
          id: featureId,
        });
      }
      for (let i = 0; i < query.length; i++) {
        if (query[i].properties.id == e.image.id) {
          featureId = query[i].id;
          map.setFeatureState(
            {
              source: 'mapillary',
              sourceLayer: 'image',
              id: featureId,
            },
            {
              clicked: true,
            }
          );
        }
      }
    });
    setMapilary(mapillaryViewer);
  }, [map]);

  useEffect(() => {
    if (imageId && mapilary) {
      mapilary.moveTo(imageId);
    }
  }, [imageId, mapilary]);
  return (
    <div className={toggleMapillary ? 'App' : 'main-street-viewer'}>
      {isLoading && (
        <div
          className={
            toggleMapillary ? 'streetViewerLoadingFull' : 'streetViewerLoading'
          }
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'inherit',
            }}
          >
            <CircularProgress style={{ color: 'white' }} />
          </div>
        </div>
      )}
      <div
        ref={containerRef}
        id={toggleMapillary ? 'fullStreetViewer' : 'streetViewer'}
        style={
          toggleMapillary
            ? { minHeight: '94.5vh', height: 'auto' }
            : { position: 'relative' }
        }
        className={isLoading ? 'hideViewer' : ''}
      >
        {!toggleMapillary ? (
          <div
            onClick={() => setToggleMapillary((val) => !val)}
            style={{
              background: 'rgba(0,0,0,0.5)',
              padding: '0px',
              height: '70px',
              width: '70px',
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'start',
              zIndex: 5,
              clipPath: 'polygon(0 0, 100% 0, 100% 100%)',
            }}
          >
            <ExpandLessIcon
              style={{ color: '#ffff', fontSize: '40px', rotate: '45deg' }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MapillaryViewer;
