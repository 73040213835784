import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: { main: "#C8DC3C" },
    secondary: {
      main: "#C8DC3C",
    },
  },
  typography: {
    fontFamily: ["Formular", "sans-serif"].join(","),
    subtitle2: {
      fontFamily: "FormularMedium, sans-serif",
      fontSize: "1rem",
    },
    button: {
      textTransform: "none",
    },
  },
});

export default theme;
